import React from 'react';
import { Outlet, Link } from 'react-router-dom';

function NoPage() {
    return (
        <div>
            <div>Error 404</div>
        </div>
    );
}
export default NoPage;